// @author: Ville Wilpola
// This file contains the ListView Styling

// Basic styling
.list-container {
    width: 80%;
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;


    // Styling the list item
    .list-item {
        width: 100%;
        margin: 10px 0;
        padding: 20px;
        background: #efefef;
        border-radius: 12px;
        line-height: 1.5;
        h3 {
            margin: 0;
            padding: 0;
        }
    }
}