.radar-container {
  width: 500px;
  height: 500px;
  background: #222225;
  margin: 0 auto;
  position: relative;
  border-radius: 8px;

  // styling the no-fly-zone
  .no-fly-zone {
    width: 200px;
    height: 200px;
    border-radius: 1000%;
    // border;
    background: rgba(185, 132, 132, 0.192);
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

.sweeper {
    height: 2px;
    height: 100px;
}
}
@keyframes sweep {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}


// Drone styling 
.drone {
  position: aboslute;
  background: green;
  width: 5px;
  height: 5px;
}